div.breadcrumb > a:first-child {
    margin-top: 1.0em;
    display: inline-block;
}
div.breadcrumb h2:first-child {
    margin-top: 0em;
}
div.tocLink {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}
div.centerLink {
    text-align: center;
    margin-top: 0.5em;
}
span.linkIcon {
    vertical-align: middle;
}
span.linkIcon > img {
    height: 1em;
}
